<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <div class="tabs">
      <v-tabs
        v-model="tab"
        background-color="light primary"
        class="elevation-2"
        dark
      >
        <v-tab>{{ $t("COMPONENTS.PROFILE") }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <vue-form-generator
                ref="form"
                :schema="userSchema"
                :model="user"
                :options="formOptions"
              ></vue-form-generator>

              <v-btn
                @click="save()"
                class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                >{{ $t("BUTTON.SAVE") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css"; // optional full css additions

import {
  GET_PROFILE,
  UPDATE_PROFILE,
  GET_TIME_TO_CHANGE_PASSWORD
} from "@/store/profile.module";

import { mapGetters, mapState } from "vuex";
// Vue.use(VueFormGenerator)
import _ from "lodash";

export default {
  name: "updateProfile",

  components: {
    "vue-form-generator": VueFormGenerator.component
  },
  computed: {
    ...mapGetters(["isLoadingUsers"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  data() {
    return {
      error_messages: [],
      model: "users",
      tab: 0,
      isFormValid: false,
      user: {
        name: null,
        email: null,
        password: null
      },
      userSchema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "Име",
            model: "name",
            required: true,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително",
              textTooSmall: "Полето трябва да бъде минимум {1} знака !!!"
            })
          },
          {
            type: "input",
            inputType: "password",
            label: "Парола",
            model: "password",
            min: 8,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително !!!",
              textTooSmall: "Полето трябва да бъде минимум {1} знака !!!"
            })
          },
          {
            type: "input",
            inputType: "password",
            label: "Потвърди паролата",
            model: "passwordConfirmation",
            min: 8,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително",
              textTooSmall: "Полето трябва да бъде минимум {1} знака !!!"
            })
          }
        ]
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: true
      }
    };
  },
  mounted() {
    let vm = this;

    _.delay(function() {
      vm.$store.dispatch(SET_BREADCRUMB, [
        { title: "Профил", route: "/profile" }
      ]);
    }, 300);

    this.getData();
  },
  methods: {
    async save() {
      let vm = this;

      vm.error_messages = [];

      let errors = await this.$refs.form.validate();

      if (errors.length) {
        return;
      }

      vm.$store
        .dispatch(UPDATE_PROFILE, { payload: vm.user })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });
          this.$store.dispatch(GET_TIME_TO_CHANGE_PASSWORD);
          vm.$router.push({ name: "dashboard" });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;

          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getData: function() {
      let vm = this;
      this.error_messages = [];
      this.$store
        .dispatch(GET_PROFILE)
        .then(data => {
          vm.$nextTick(function() {
            vm.user = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "usersList" });
          }
        });
    }
  }
};
</script>
